import styled from '@emotion/styled';
// import DFSymbolTransparent from 'assets/icons/DF_symbol_transparent.svg';
import { CSSProperties, FunctionComponent } from 'react';

import { isMedia } from '@/utils/typescript';

import { Media } from '../../payload-types';

interface MediaComponentProps {
  source: Media | string | undefined;
  style?: CSSProperties;
}

const StyledVideo = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 0px;
  display: block;
`;

const MediaComponent: FunctionComponent<MediaComponentProps> = ({ source, style }) => {
  if (source && isMedia(source)) {
    switch (source.mimeType) {
      case 'video/mp4':
        return <StyledVideo src={source.url as string} autoPlay muted loop playsInline style={style} />;
      default:
        return <StyledImage src={source.url as string} alt={source.alt as string} width="100%" height="100%" style={style} />;
    }
  } else if(typeof source == "string"){
      return <StyledImage src={source} width="100%" height="100%" style={style} />;
  } else return <></>;
};

export { MediaComponent };
