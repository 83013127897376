import styled from '@emotion/styled';
import { Box } from '@material-ui/core';
import { FC, Fragment } from 'react';

import DFStartLogo from '@/assets/icons/DF_logo_article_start.svg';
import DragonLogo from '@/assets/icons/dragon-logo.svg';
import { ArticleRichtext } from '@/payload-types';
import { mqMax } from '@/styles/breakpoints';

import { serialize } from '../utils/serializer/RichTextSerializer';

interface RichtextBlockProps {
  block: ArticleRichtext;
  isFirst?: boolean;
  isLast?: boolean;
  isArticle?: boolean;
  noMargins?: boolean;
}
const RichtextContainer = styled.div<{ isArticle?: boolean, noMargins?: boolean }>`
  max-width: 620px;
  ${({ noMargins }) => !noMargins && `margin: 6rem auto;`}
  ${({ isArticle }) => !isArticle && `color: #ebebeb;`}
  ${mqMax[3]} {
    ${({ noMargins }) => !noMargins && `
      padding: 2rem;
      margin: 2rem auto;
    `}
  }
  .logo {
    float: left;
    width: 60px;
    margin-right: 12px;
    shape-outside: circle();
    shape-margin: 1em;
  }

  a {
    color: inherit;
    position: relative;
    text-decoration: none;
    transform-style: preserve-3d;

    box-shadow: 0 0 #0000, 0 0 #0000, inset 0 -1px 0 0 #EC6446;

    &:hover {
      box-shadow: 0 0 #0000, 0 0 #0000, inset 0 -.5em 0 0 #EC6446;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
  }

  p {
    margin: 0.3em 0 0.7em 0;
  }

  h1 {
    margin: 1.6em 0 0.4em 0;
  }

  h2 {
    margin: 1.5em 0 0.6em 0;
  }

  h3 {
    margin: 1.8em 0 0.7em 0;
  }

  ul {
    list-style-type: "->";
  }

  ul, ol {
    font-size: 19px;
    font-weight: 300;
  }

  li {
    line-height: 1.6;
    margin-bottom: 0.5em;
    padding-left: 1rem;
  }

  strong {
    font-weight: 500; 
  }
`;

const DragonBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 48px;
    margin-top: 2rem;
    margin-bottom: 9rem;
    
    ${mqMax[5]} {
      margin-top: 0rem;
      margin-bottom: 7rem;
    }

  }
`;

const LastLine = styled.div`

  width: 300px;
  position: relative;
  height: 1px;
  background-color: #0C0C0C;
  opacity: 0.2;
  margin: 0 auto;
  transform: translateY(1px);
  z-index: 11;

  ${mqMax[4]} {
    width: calc(100% - 4rem);
    margin: 2rem auto;
  }
`;

const TopMargin = styled.div`
  margin-top: 20rem;

  ${mqMax[3]} {
    margin-top: 6rem;
  }
`;

const RichtextBlock: FC<RichtextBlockProps> = ({ block, isFirst = false, isArticle = false, isLast = false, noMargins = false }) => {
  const elements = serialize((block.text as any)?.root?.children);
  return (
    <>
      <TopMargin style={{
        display: isFirst && !isArticle ? 'block' : 'none'
      }}></TopMargin>
      <RichtextContainer isArticle={isArticle} noMargins={noMargins}>
        {
          isArticle && isFirst && (<img src={DFStartLogo.src} className="logo" />)
        }
        {elements?.map((element, index) => <Fragment key={index}>{element}</Fragment>)}
      </RichtextContainer>
      {isLast && isArticle && (
        <DragonBox>
          <img src={DragonLogo.src} alt="DF logo" />
          <LastLine />
        </DragonBox>
      )}
    </>
  );
};

export { RichtextBlock };
