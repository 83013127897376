import axios from 'axios';
import { differenceInDays } from 'date-fns';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { GetMagineIdBasicMovieQuery } from '@/graphql/queries/__generated__/movie.generated';
import { IAuthUser } from '@/models/IAuthUser.interface';

import { createVariablesObject, createViewables, getMoviesGraphQLQueryArguments } from './utils';

const recommendationsData: Map<
  string,
  {
    timestamp: Date;
    recommendations: Array<GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }>;
  }
> = new Map();

const RECOMMENDATIONS_LIFETIME = 10;

interface UseRecommendationsProps {
  authUser: IAuthUser;
  clientIp: string;
  avoidFetch?: boolean;
}

export const useRecommendations = ({ authUser, clientIp, avoidFetch = false }: UseRecommendationsProps) => {
  const [recommendations, setRecommendations] = useState<
    Array<GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }>
  >([]);
  const [recommendationsIds, setRecommendationsIds] = useState<string[]>([]);
  const [areRecommendationsLoading, setAreRecommendationsLoading] = useState(false);

  const getRecommendationsIds = useCallback(() => {
    if (authUser?.userId && !avoidFetch) {
      setAreRecommendationsLoading(true);
      axios
        .get<string[]>(`/api/recommendations/user/${authUser?.userId}`)
        .then((response) => {
          if (response.data.length) {
            setRecommendationsIds(response.data);
          } else {
            setAreRecommendationsLoading(false);
          }
        })
        .catch((error) => {
          setAreRecommendationsLoading(false);
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  }, [authUser?.userId, avoidFetch]);

  const getRecommendations = useCallback(() => {
    if (authUser?.token && recommendationsIds.length > 0 && !avoidFetch) {
      axios({
        url: process.env.NEXT_PUBLIC_MAGINE_API_QGL_V2,
        data: {
          variables: createVariablesObject(recommendationsIds),
          context: { authToken: authUser?.token, clientIp },
          query: `
            query GetMagineMovies(${getMoviesGraphQLQueryArguments(recommendationsIds)}) {
              viewer {
                ${createViewables(recommendationsIds)}
              }
            }
          `,
        },
        method: 'post',
        headers: authUser.token
          ? {
              'Magine-AccessToken': process.env.NEXT_PUBLIC_MAGINE_API_TOKEN,
              Authorization: `Bearer ${authUser.token}`,
              'X-Forwarded-For': clientIp,
            }
          : {
              'Magine-AccessToken': process.env.NEXT_PUBLIC_MAGINE_API_TOKEN,
              'X-Forwarded-For': clientIp,
            },
      })
        .then((response) => {
          if (response?.data?.data?.viewer) {
            const mappedRecommendations = Object.values(response?.data?.data?.viewer).filter((movie) => movie);
            recommendationsData.set(authUser?.userId ?? '', {
              timestamp: new Date(),
              recommendations: mappedRecommendations as Array<
                GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }
              >,
            });
            setRecommendations(
              mappedRecommendations as Array<GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }>
            );
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        })
        .finally(() => {
          setAreRecommendationsLoading(false);
        });
    }
  }, [authUser?.token, authUser?.userId, avoidFetch, clientIp, recommendationsIds]);

  const cachedRecommendations = useMemo(
    () => (authUser?.userId ? recommendationsData.get(authUser.userId) : null),
    [authUser?.userId]
  );

  const recommendationsAreValid = useMemo(
    () =>
      isEqual(
        cachedRecommendations?.recommendations.map((recommendation) => recommendation?.magineId),
        recommendationsIds
      ),
    [cachedRecommendations?.recommendations, recommendationsIds]
  );
  const recommendationsExpired = useMemo(
    () =>
      cachedRecommendations
        ? differenceInDays(new Date(), cachedRecommendations.timestamp) > RECOMMENDATIONS_LIFETIME
        : false,
    [cachedRecommendations]
  );
  useEffect(() => {
    getRecommendationsIds();
  }, [getRecommendationsIds]);

  useEffect(() => {
    if (!cachedRecommendations || recommendationsExpired || !recommendationsAreValid) {
      if (recommendationsIds) {
        getRecommendations();
      }
    } else {
      setRecommendations(cachedRecommendations.recommendations);
    }
  }, [cachedRecommendations, getRecommendations, recommendationsAreValid, recommendationsExpired, recommendationsIds]);

  return { recommendations, areRecommendationsLoading };
};
