import { Box } from '@material-ui/core';
import { FC } from 'react';

import Carousel from '@/components/ThemedCarousel';
import { MovieCard } from '@/components/ThemedMovieCard';
import { LinkEdge } from '@/graphql/queries/__generated__/baseTypes.generated';
import { GetMagineIdBasicMovieQuery } from '@/graphql/queries/__generated__/movie.generated';
import { ViewableEdge } from '@/models/ICollection.interface';
import { ICollectionTheme } from '@/models/ILandingPage.interface';
import { movieClick } from '@/utils/analytics';
import getMeta from '@/utils/getMeta';
import _theme from '@/theme/theme';

import {
  Background,
  CollectionInfo,
  FirstEmptySlide,
  FirstSlideStylesContainer,
  Headline,
  Subtitle,
  ThemeTitleImage,
  ThemeTitleWrapper,
  Wrapper,
} from './Collection.styles';

import LinkButton from '../Button/LinkButton';
import ThemedCategoryCard from '../ThemedCategoryCard/ThemedCategoryCard';
import { Typography } from '../Typography';

interface CollectionProps {
  magineId?: string;
  image?: string | null;
  title?: string | null;
  description?: string | null;
  textColor?: string;
  linksEdges?: Array<Omit<LinkEdge, 'cursor'> | null> | null;
  viewableEdges?: Array<ViewableEdge | null> | null;
  movies?: Array<GetMagineIdBasicMovieQuery['viewer']['viewable'] & { __typename: 'Movie' }>;
  background?: string;
  theme?: ICollectionTheme;
}
export const Collection: FC<CollectionProps> = ({
  textColor = '#FFFFFF',
  magineId,
  image,
  description,
  title,
  linksEdges,
  viewableEdges,
  movies,
  theme,
}) => {
  const predictSlidesToShow = (edges: Array<ViewableEdge | null>) => {
    const isViewLink = edges?.some((movie) => movie?.node?.__typename === 'CollectionLink');
    return isViewLink ? 3.5 : 5.7;
  };

  const hasDescription = !!description && !description.startsWith('#');

  return viewableEdges?.length ?? linksEdges?.length ?? movies?.length ? (
    <Wrapper style={{
      marginTop: "6rem",
      paddingBottom: image ? "3rem" : ""
    }}>
      {(theme?.headlineImage ?? theme?.subtitleImage ?? theme?.headline ?? theme?.subtitle) && (
        <ThemeTitleWrapper accentColor={theme.accentColor}>
          {theme?.headline && !theme?.headlineImage && <Headline>{theme.headline}</Headline>}
          {theme?.subtitle && !theme?.subtitleImage && <Subtitle>{theme.subtitle}</Subtitle>}
          {theme?.headlineImage && <ThemeTitleImage src={theme.headlineImage.url} alt="" />}
          {theme?.subtitleImage && <ThemeTitleImage src={theme.subtitleImage.url} alt="" />}
        </ThemeTitleWrapper>
      )}

      {image && <Background background={image} overlay={true} />}

      {hasDescription && image ? (
        <>
          <CollectionInfo>
            <Typography variant="titleLarge" fontWeight='500'>{title}</Typography>
            <Box mb="3rem" mt={3}>
              <Typography variant="bodyMedium">{description}</Typography>
            </Box>
            {magineId && (
              <LinkButton path={`/kollektion/${magineId}`} align="left" arrowPlacement="right" fontWeight="500" style={{
                paddingLeft: "0"
              }}>
                Se mer
              </LinkButton>
            )}
          </CollectionInfo>
        </>
      ) : null}

      {!hasDescription && !image && (
        <Box pl={{ xs: '1rem', md: '10rem' }} pb="2rem">
          {magineId ? (
            <LinkButton path={`/kollektion/${magineId}`} align="left" arrowPlacement="right" fontWeight="500" style={{
              textTransform: 'uppercase',
              letterSpacing: "1px"
            }}>
              {title}
            </LinkButton>
          ) : (
            <LinkButton align="left" arrowPlacement="right" fontWeight="500" style={{
              textTransform: 'uppercase',
              letterSpacing: "1px"
            }}>
              {title}
            </LinkButton>
          )}
        </Box>
      )}

      {linksEdges && !!linksEdges.length ? (
        <FirstSlideStylesContainer>
          <Carousel slidesToShow={predictSlidesToShow(linksEdges)} size={linksEdges.length}>
            <FirstEmptySlide />
            {linksEdges.map((movie) => (
              <Box pl={2} pr={2} key={movie?.node.magineId}>
                {movie?.node.__typename === 'CollectionLink' ? (
                  <ThemedCategoryCard
                    thumbnail={movie?.node.image}
                    title={movie?.node.title}
                    magineId={movie.node.magineId}
                  />
                ) : (
                  <MovieCard
                    textColor={textColor}
                    title={movie?.node.title}
                    thumbnail={movie?.node.image}
                    onClick={() => movieClick(movie?.node, getMeta('pageType'), title)}
                  />
                )}
              </Box>
            ))}
          </Carousel>
        </FirstSlideStylesContainer>
      ) : null}
      {viewableEdges && !!viewableEdges.length ? (
        <FirstSlideStylesContainer>
          <Carousel slidesToShow={predictSlidesToShow(viewableEdges)} size={viewableEdges.length}>
            <FirstEmptySlide />
            {viewableEdges.map((movie) => (
              <Box pl={2} pr={2} key={movie?.node.magineId}>
                {movie?.node?.__typename === 'CollectionLink' ? (
                  <ThemedCategoryCard
                    thumbnail={movie?.node.image}
                    title={movie?.node.title}
                    magineId={movie.node.magineId}
                  />
                ) : (
                  <MovieCard
                    textColor={textColor}
                    customId={movie?.node.customId}
                    title={movie?.node.title}
                    thumbnail={movie?.node.image}
                    onClick={() => movieClick(movie?.node, getMeta('pageType'), title)}
                  />
                )}
              </Box>
            ))}
          </Carousel>
        </FirstSlideStylesContainer>
      ) : null}

      {movies?.length && (
        <FirstSlideStylesContainer>
          <Carousel size={movies?.length}>
            <FirstEmptySlide />
            {movies.map((movie) => (
              <Box pl={2} pr={2} key={movie.id}>
                <MovieCard
                  title={movie.title}
                  thumbnail={movie.image}
                  customId={movie.customId}
                  onClick={() => movieClick(movie, getMeta('pageType'), title)}
                />
              </Box>
            ))}
          </Carousel>
        </FirstSlideStylesContainer>
      )}
    </Wrapper>
  ) : null;
};
