import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { arrowCursorDataURI } from './utils';
import { mqMax } from '@/styles/breakpoints';

export interface FullWidth {
  fullWidth?: boolean;
}

interface ArrowProps {
  color?: string;
}

const getArrowStyles = () => css`
  position: absolute;
  top: 0px;
  height: 100%;
  z-index: 400;
  outline: none;
  width: 100px;
  border: none;

  ${mqMax[4]} {
    display: none;
  }
`;

export const StyledPrevArrow = styled.div<ArrowProps>`
  ${getArrowStyles};
  visibility: ${(props) => (props.onClick ? 'visible' : 'hidden')};

  :hover {
    cursor: url(${(props) => arrowCursorDataURI(props.color, 'left')}) -64 32, pointer;
  }
`;

export const StyledNextArrow = styled.div<ArrowProps>`
  ${getArrowStyles};
  right: 0;
  visibility: ${(props) => (props.onClick ? 'visible' : 'hidden')};

  :hover {
    background-position: 0 100%;
    cursor: url(${(props) => arrowCursorDataURI(props.color, 'right')}) 64 32, pointer;
  }
`;

export const Wrapper = styled.div<FullWidth>`
  max-width: ${({ fullWidth }) => (fullWidth ? 'unset' : '100vw')};
  width: ${({ fullWidth }) => (fullWidth ? 'unset' : '100%')};
`;
