import { css } from '@emotion/react';
import { FC, useState } from 'react';

import { useBookmarks } from '@/context/bookmarks/bookmarks.provider';

import { BoomarkMovieCardWrapper, StyledBookmarkImage, TitleContainer } from './BookmarksCollection.styles';

import { Typography } from '../Typography';
import RemoveIconWhite from '../icons/new/RemoveIconWhite';

interface MovieCardProps {
  title?: string | null;
  thumbnail?: string | null;
  magineId?: string;
}

export const BookmarkMovieCard: FC<MovieCardProps> = ({ title, thumbnail, magineId }) => {
  const [isHovered, setIsHovered] = useState(false);

  const { removeFromFavorites } = useBookmarks();
  const handleClick = () => {
    if (magineId) {
      removeFromFavorites(magineId);
    }
  };

  return (
    <BoomarkMovieCardWrapper onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div>
        {thumbnail && (
          <StyledBookmarkImage alt={title ?? ''} src={thumbnail} width={256} height={366} isHovered={true} />
        )}
        <TitleContainer isHovered={true} onClick={handleClick}>
          <div>
            <RemoveIconWhite />
          </div>
          <Typography
            variant="labelMedium"
            align="center"
            fontStyle="italic"
            styles={css`
              width: 100%;
              flex-grow: 1;
            `}
          >
            Ta bort från min lista
          </Typography>
        </TitleContainer>
      </div>
    </BoomarkMovieCardWrapper>
  );
};
