import { ReactNode, createElement } from 'react';

import {
  IS_BOLD,
  IS_CODE,
  IS_ITALIC,
  IS_STRIKETHROUGH,
  IS_SUBSCRIPT,
  IS_SUPERSCRIPT,
  IS_UNDERLINE,
} from './RichTextNodeFormat';
import type { SerializedLexicalNode } from './types';

export function serialize(children: SerializedLexicalNode[]): ReactNode[] {
  return children?.map((node): ReactNode | null => {
    if (node.type === 'text') {
      const text = node.text;
      let formattedElement: ReactNode = text;

      if (node.format & IS_BOLD) {
        formattedElement = createElement('strong', {}, formattedElement);
      }
      if (node.format & IS_ITALIC) {
        formattedElement = createElement('em', {}, formattedElement);
      }

      if (node.format & IS_STRIKETHROUGH) {
        formattedElement = createElement('span', { style: { textDecoration: 'line-through' } }, formattedElement);
      }

      if (node.format & IS_UNDERLINE) {
        formattedElement = createElement('span', { style: { textDecoration: 'underline' } }, formattedElement);
      }

      if (node.format & IS_CODE) {
        formattedElement = createElement('code', {}, formattedElement);
      }

      if (node.format & IS_SUBSCRIPT) {
        formattedElement = createElement('sub', {}, formattedElement);
      }

      if (node.format & IS_SUPERSCRIPT) {
        formattedElement = createElement('sup', {}, formattedElement);
      }

      return formattedElement;
    }

    if (!node) {
      return null;
    }

    const serializedChildren = node.children ? serialize(node.children) : null;

    switch (node.type) {
      case 'linebreak':
        return createElement('br');
      case 'link': {
        const linkProps = {
          href: node?.fields?.linkType === 'custom' ? node?.fields?.url : '',
          target: node?.fields?.newTab ? '_blank' : '',
          rel: `${node?.rel ?? ''}${node?.sponsored ? ' sponsored' : ''}${node?.nofollow ? ' nofollow' : ''}`,
        };

        return createElement('a', linkProps, ...(serializedChildren ?? [])); // Use empty array if serializedChildren is null
      }

      case 'list':
        if (node.listType === 'bullet') {
          return createElement('ul', { className: 'list-disc mb-4 pl-8' }, ...(serializedChildren ?? []));
        } else {
          return createElement('ol', { className: 'list-disc mb-4 pl-8' }, ...(serializedChildren ?? []));
        }
      case 'listitem':
        return createElement('li', {}, ...(serializedChildren ?? []));
      case 'heading':
        return createElement(node.tag, {}, ...(serializedChildren ?? []));
      default:
        // Probably just a normal paragraph
        return createElement('p', {}, ...(serializedChildren ?? [])) ?? createElement('br');
    }
  });
}
