import { useTheme } from '@material-ui/core';
import { PropsWithChildren, forwardRef } from 'react';
import Slider, { CustomArrowProps, Settings } from 'react-slick';

import { FullWidth, StyledNextArrow, StyledPrevArrow, Wrapper } from './Carousel.styles';

export const ANIMATION_SPEED = 500;

interface ArrowProps {
  color?: string;
}

function NextArrow(props: CustomArrowProps & ArrowProps) {
  const { onClick } = props;

  return <StyledNextArrow onClick={onClick} color={props.color} />;
}

function PrevArrow(props: CustomArrowProps & ArrowProps) {
  const { onClick } = props;

  return <StyledPrevArrow onClick={onClick} color={props.color} />;
}

type CarouselProps = PropsWithChildren<
  {
    slidesToShow?: number;
    size?: number;
    variableWidth?: boolean;
    handleSlideUpdate?: (index: number) => void;
    color?: string;
  } & FullWidth
>;

export const Carousel = forwardRef<Slider, CarouselProps>(
  ({ children, slidesToShow = 9, variableWidth = false, fullWidth, handleSlideUpdate, color }, ref) => {
    const theme = useTheme();

    const settings: Settings = {
      dots: false,
      infinite: false,
      speed: ANIMATION_SPEED,
      slidesToShow: variableWidth ? 1 : Math.min(slidesToShow, 5.7),
      slidesToScroll: Math.min(slidesToShow, 2),
      nextArrow: <NextArrow color={color ?? theme.palette.primary.main} />,
      prevArrow: <PrevArrow color={color ?? theme.palette.primary.main} />,
      variableWidth,
      adaptiveHeight: false,

      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: variableWidth ? 1 : Math.min(slidesToShow, 5.7),
            slidesToScroll: Math.min(slidesToShow, 2),
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: variableWidth ? 1 : Math.min(slidesToShow, 3.5),
            slidesToScroll: Math.min(slidesToShow, 2),
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: variableWidth ? 1 : Math.min(slidesToShow, 2.5),
            slidesToScroll: Math.min(slidesToShow, 2),
          },
        },
        {
          breakpoint: 490,
          settings: {
            slidesToShow: variableWidth ? 1 : Math.min(slidesToShow, 2.5),
            slidesToScroll: Math.min(slidesToShow, 2),
          },
        },
      ],
      afterChange(currentSlide: number) {
        handleSlideUpdate?.(currentSlide);
      },
    };

    return (
      <>
        <Wrapper fullWidth={fullWidth}>
          <Slider {...settings} ref={ref}>
            {children}
          </Slider>
        </Wrapper>
      </>
    );
  }
);

Carousel.displayName = 'Carousel';
