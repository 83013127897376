import { Category } from 'aws-sdk/clients/cloudformation';
import axios from 'axios';

import { initializeApollo } from '@/graphql/client';
import { GetBasicCollectionsDocument } from '@/graphql/queries/__generated__/collection.generated';
import { GetCustomIdFullMovieDocument } from '@/graphql/queries/__generated__/movie.generated';
import { IAuthUser } from '@/models/IAuthUser.interface';
import { ILandingPage } from '@/models/ILandingPage.interface';
import { IMovieAsset } from '@/models/IMovieAsset.interface';
import { Article, CollectionsStylesConfig, ContactPage, MyPage, Page, Setting } from '@/payload-types';
import { PayloadCollectionResponse } from '@/utils/typescript';

const apolloClient = initializeApollo();

// Magine

export const getMovieAsset = async (magineId?: string | string[], token?: string | null) =>
  await axios.post<IMovieAsset>(`${process.env.NEXT_PUBLIC_BASE_URL}/api/movies/${token ?? null}`, {
    magineId,
  });

interface GetCollectionsProps {
  authUser: IAuthUser | null;
  viewId: string;
  clientIp: string | string[];
}

export const getCollections = async ({ authUser, viewId, clientIp }: GetCollectionsProps) => {
  return await apolloClient.query({
    query: GetBasicCollectionsDocument,
    context: {
      authToken: authUser?.token,
      clientIp,
    },
    variables: { id: viewId },
    fetchPolicy: 'no-cache',
  });
};

interface GetMovieProps {
  authUser: IAuthUser | null;
  slug?: string | string[];
  clientIp: string | string[];
}

export const getMovie = async ({ authUser, clientIp, slug }: GetMovieProps) => {
  return await apolloClient.query({
    query: GetCustomIdFullMovieDocument,
    context: { authToken: authUser?.token, clientIp },
    variables: { customId: slug },
    fetchPolicy: 'no-cache',
  });
};

// Payload Collections

export const searchPayloadArticles = async (stringifiedQuery: string) => {
  console.log('stringifiedQuery', `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/articles/${stringifiedQuery}`);
  return await axios.get<PayloadCollectionResponse<Article>>(
    `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/articles/${stringifiedQuery}`
  );

}

export const searchPayloadPages = async (stringifiedQuery: string) =>
  await axios.get<PayloadCollectionResponse<Page>>(
    `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/pages${stringifiedQuery}`
  );

export const getPayloadArticlesCategories = async () =>
  await axios.get<PayloadCollectionResponse<Category>>(`${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/categories`);

export const getCollectionsStylesConfig = async () =>
  await axios.get<PayloadCollectionResponse<CollectionsStylesConfig>>(
    `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/collections-styles-config?limit=100`
  );

export const searchCollectionsStylesConfig = async (stringifiedQuery: string) =>
  await axios.get<PayloadCollectionResponse<CollectionsStylesConfig>>(
    `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/collections-styles-config/${stringifiedQuery}`
  );

// Payload Globals

export const getMyPageData = async () =>
  await axios.get<MyPage>(
    `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/globals/my-pages?locale=undefined&draft=false&depth=1`
  );

export const getContactPageData = async () =>
  await axios.get<ContactPage>(
    `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/globals/contact-page?locale=undefined&draft=false&depth=1s`
  );

export const getSettingsData = async () =>
  await axios.get<Setting>(
    `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/globals/settings?locale=undefined&draft=false&depth=1s`
  );

// Strapi

export const getLandingPage = async () =>
  await axios.get<ILandingPage>(`${process.env.NEXT_PUBLIC_CMS_URL}/landingpage`);

// Next Backend

interface GetEntitlementsProps {
  clientIp: string | string[];
  userInfo: {
    token: string | null;
    assetId: string;
  };
}

export const getEntitlements = async ({ clientIp, userInfo }: GetEntitlementsProps) =>
  await axios.post(`${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/entitlement`, userInfo, {
    headers: {
      'X-Forwarded-For': clientIp,
    },
  });
