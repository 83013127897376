export const arrowCursor = (color = '#EC6446', direction: 'left' | 'right' = 'left', stroke = 3) =>
  `<?xml version="1.0" encoding="utf-8"?>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="80" height="80" viewBox="0 0 98 69" style="enable-background:new 0 0 98 69;" xml:space="preserve">
    <style type="text/css">
	    .st0{fill:none;stroke:${color};stroke-width:${stroke};stroke-miterlimit:10;}
    </style>
    ${direction === 'left' ?
      `<path class="st0" d="M41.4,1C34.9,32.8,0,34.5,0,34.5S34.9,36.2,41.4,68"/><path class="st0" d="M4.9,34.5H97"/>` :
      `<path class="st0" d="M55.6,68C62.1,36.2,97,34.5,97,34.5S62.1,32.8,55.6,1"/><path class="st0" d="M92.1,34.5H0"/>`
    }
</svg>`;

export const arrowCursorDataURI = (color?: string, direction?: 'left' | 'right', stroke: number = 3) =>
  `data:image/svg+xml;base64,${btoa(arrowCursor(color, direction, stroke))}`;
