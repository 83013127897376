import { Box } from '@material-ui/core';
import { FC, useState } from 'react';

import Carousel from '@/components/ThemedCarousel';
import { MovieCard } from '@/components/ThemedMovieCard';
import { LinkEdge } from '@/graphql/queries/__generated__/baseTypes.generated';
import { ViewableEdge } from '@/models/ICollection.interface';
import { movieClick } from '@/utils/analytics';
import getMeta from '@/utils/getMeta';

import { BookmarkMovieCard } from './BookmarkMovieCard';

import LinkButton from '../Button/LinkButton';
import { FirstEmptySlide, FirstSlideStylesContainer, Wrapper } from '../Collection/Collection.styles';

interface CollectionProps {
  magineId?: string;
  linksEdges?: Array<Omit<LinkEdge, 'cursor'> | null> | null;
  title?: string | null;
  viewableEdges?: Array<ViewableEdge | null> | null;
}
export const BookmarksCollection: FC<CollectionProps> = ({ magineId, title, linksEdges, viewableEdges }) => {
  const [editing, isEditing] = useState(false);

  const predictSlidesToShow = (edges: Array<ViewableEdge | null>) => {
    const isViewLink = edges?.some((movie) => movie?.node?.__typename === 'CollectionLink');
    return isViewLink ? 2.5 : 5.5;
  };

  const toggleIsEditing = () => {
    isEditing(!editing);
  };

  return viewableEdges?.length ?? linksEdges?.length ? (
    <Wrapper>
      <Box mt="6rem" pl={{ xs: '1rem', md: '10rem' }} pb="2rem" display="flex" justifyContent="space-between">
        {magineId ? (
          <LinkButton path={`/kollektion/${magineId}`} align="left" arrowPlacement="right" fontWeight="400" style={{
            letterSpacing: '1px',
          }}>
            {title}
          </LinkButton>
        ) : (
          <LinkButton align="left" arrowPlacement="right" fontWeight="400">
            {title}
          </LinkButton>
        )}
        <Box pr={{ xs: '1rem', md: '4rem' }} onClick={toggleIsEditing}>
          <LinkButton fontWeight="400">{editing ? 'Klar' : 'Redigera min lista'} </LinkButton>
        </Box>
      </Box>

      {viewableEdges && !!viewableEdges.length ? (
        <FirstSlideStylesContainer>
          <Carousel slidesToShow={predictSlidesToShow(viewableEdges)} size={viewableEdges.length}>
            <FirstEmptySlide />
            {viewableEdges.map((movie) => (
              <Box pl={2} pr={2} key={movie?.node.magineId}>
                {editing ? (
                  <BookmarkMovieCard
                    title={movie?.node.title}
                    thumbnail={movie?.node.image}
                    magineId={movie?.node.magineId}
                  />
                ) : (
                  <MovieCard
                    title={movie?.node.title}
                    thumbnail={movie?.node.image}
                    magineId={movie?.node.magineId}
                    customId={movie?.node.customId}
                    onClick={() => movieClick(movie?.node, getMeta('pageType'), title)}
                  />
                )}
              </Box>
            ))}
          </Carousel>
        </FirstSlideStylesContainer>
      ) : null}
    </Wrapper>
  ) : null;
};
