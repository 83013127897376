export const createVariablesObject = (ids: string[]) => {
  const obj: { [key: string]: string } = {};
  ids.forEach((elem, i) => {
    obj[`magineId${i}`] = elem;
  });
  return obj;
};

export const getMoviesGraphQLQueryArguments = (ids: string[]) =>
  ids.map((_, index) => ` $magineId${index}: ID!`).join(', ');

export const createViewables = (ids: string[]) =>
  ids
    .map(
      (_, index) =>
        `viewable${index} : viewable(magineId: $magineId${index}) {
            __typename
            customId
            id
            magineId
            title
            image: image(type: "poster")
          }
          `
    )
    .join('');
