import styled from '@emotion/styled';

export const StyledBookmarkImage = styled.img<{ hoverColor?: string; isHovered?: boolean }>`
  transition: outline 0.2s ease-out;
  width: 100%;
  aspect-ratio: '256/366';
  height: auto;
  ${(props) => (props.isHovered ? `opacity: 0.3; background: rgba(0, 0, 0, 1);` : '')}
  :hover {
    opacity: 0.3;
    background: rgba(0, 0, 0, 1);
  }
`;

export const BoomarkMovieCardWrapper = styled.div`
  position: relative;
`;

interface TitleContainerProps {
  isHovered?: boolean;
}

export const TitleContainer = styled.div<TitleContainerProps>`
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 80%;
  height: 3rem;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: ${(props) => (props.isHovered ? 'visible' : 'hidden')};
`;
