import styled from '@emotion/styled';
import { Box, Theme, makeStyles, useMediaQuery } from '@material-ui/core';
import NextLink from 'next/link';
import { FC, useState } from 'react';

import { mqMax } from '@/styles/breakpoints';

import { StyledImage } from '../ThemedMovieCard/MovieCard.styles';
import { Typography } from '../Typography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    textDecoration: 'none',
  },
  image: {
    width: '100%',
    height: 'auto',
    boxShadow: '0 1.5rem 2rem -1.5rem rgba(0,0,0,.6)',
  },
  title: {
    fontSize: 16,
    fontWeight: 100,
    marginTop: theme.spacing(2),
    wordBreak: 'break-word',
  },
}));

interface CategoryCardProps {
  title?: string | null;
  thumbnail?: string | null;
  textColor?: string;
  hoverColor?: string;

  magineId?: string;
}

interface TitleProps {
  show?: boolean;
}

const TitleContainer = styled.div<TitleProps>`
  margin-top: 12px;
  word-break: break-word;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`;

const ThemedCategoryCard: FC<CategoryCardProps> = ({
  title,
  thumbnail,
  textColor = '#FFFFFF',
  hoverColor,
  magineId,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles();

  const isMobile = useMediaQuery(mqMax[3]);

  return (
    <NextLink
      className={classes.root}
      href={`/kollektion/${magineId}`}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box>
        {thumbnail ? (
          <StyledImage
            alt={title ?? ''}
            src={thumbnail}
            isHorizontal={true}
            width={455}
            height={256}
            hoverColor={hoverColor}
          />
        ) : null}

        <TitleContainer show={isMobile ?? isHovered}>
          <Typography variant="bodySmall" customColor={textColor}>
            {title}
          </Typography>
        </TitleContainer>
      </Box>
    </NextLink>
  );
};

export default ThemedCategoryCard;
