import styled from '@emotion/styled';
import { Button as MuiButton, Typography } from '@material-ui/core';

import { media } from '@/styles';
import { mqMax, mqMin } from '@/styles/breakpoints';

export const Wrapper = styled.div`
  position: relative;
`;

export const PictureHighlight = styled.div<{ image?: string }>`
  position: relative;
  display: flow-root;
  flex-grow: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${({ image }) => (image ? `url(${image})` : 'unset')};
  align-items: flex-end;
  min-height: 80rem;

  ${media.md} {
    min-height: 60rem;
  }
`;

export const Headline = styled.div`
  font-size: 50px;
  line-height: 1.2;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

export const Subtitle = styled.div`
  font-size: 24px;
`;

export const ThemeTitleWrapper = styled.div<{ accentColor?: string }>`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.mobileStepper};
  margin-bottom: ${({ theme }) => theme.spacing(10)}px;
  text-align: center;
  color: ${({ accentColor }) => accentColor ?? 'unset'};
  font-weight: 600;
`;

export const ThemeTitleImage = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
`;

export const Background = styled.div<{ background: string; overlay?: boolean }>`
  position: absolute;
  inset: 0;

  background-image: ${({ background }) =>
  background ? `url("${background}")` : 'unset'};
  background-color: #aaa;
  background-size: cover;
  background-position: center;
  background-blend-mode: ${({ overlay }) => (overlay ? 'multiply' : 'unset')};

  ${media.sm} {
    // margin: ${({ theme }) => theme.spacing(0, -10)};
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(10, 10, 10, 0.7) 0%, rgba(10, 10, 10, 0.4) 100%);
`;

export const CollectionInfo = styled.div`
  position: relative;
  bottom: 0;
  padding: 6rem 6rem 3rem 10rem;
  max-width: 600px;
  ${mqMax[3]} {
    padding: 6rem 4rem;
  }
`;

export const Title = styled(Typography)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 26px;
`;

export const PopularTitle = styled(Typography)`
  font-size: 42px;
  font-weight: 500;
  line-height: 1.1;
`;

export const Button = styled(MuiButton)`
  white-space: nowrap;
`;

export const ButtonHighlight = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing(-1)}px;
  white-space: nowrap;
`;

export const FirstSlideStylesContainer = styled.div`
    .slick-slide:first-of-type {
      width: 1rem !important;
      ${mqMin[3]} {
        width: 10rem !important;
      }
    }
  }
`;
export const FirstEmptySlide = styled.div``;
